<template>
  <div style="padding: 0 20px">
    <v-card style="padding: 0;">
      <v-layout wrap class="result-head" style="padding:10px 20px;">
        <v-flex xs3 style="font-size: 2rem;" class="result-head">
          {{ $t('turismo.survey.creationDate', locale) }}
        </v-flex>
        <v-flex xs3 style="text-align: left;font-size: 2rem;" class="result-head">
          <v-menu>
      <template v-slot:activator="{ on: menu, attrs }">
            <span
              dark
              v-bind="attrs"
              v-on="{ ...menu }"
              text class="result-head"
            >
            {{ $t('turismo.survey.visitorCenter', locale) }} {{ selectedVisitorCenter ? `( ${selectedVisitorCenter})` : '' }}
            </span>
      </template>
      <v-list>
        <v-list-item
          v-for="(item, index) in visitorCenters"
          :key="index"
          @click="handleSelectVisitorCenter(item)"
        >
          <v-list-item-title>{{ item.Name }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
        </v-flex>
        <v-flex xs6 style="text-align: left;font-size: 2rem;" class="result-head">
          {{ $t('turismo.survey.comment', locale) }}
        </v-flex>
      </v-layout>

      <v-layout wrap
        v-for="item of results"
        :key="item.ID"
        style="border-top: 1px solid #CCCCCC;padding:10px 20px;"
      >
        <v-flex xs3 class="result-row">
          {{getDate(item.LMD)}}
        </v-flex>
        <v-flex xs3 style="text-align: left" class="result-row">
          {{item.Name}}
        </v-flex>
        <v-flex xs6 style="text-align: left" class="result-row">
          {{item.Result.Comment}}
        </v-flex>
      </v-layout>
      <pagination
        :startIndex="startIndex"
        :itemsPerPage="itemsPerPage"
        :count="count"
        :onGetData="onGetData"
        :locale="locale"
      />
    </v-card>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import utils from '@/services/utils'
import api from '@/services/api'
import Pagination from '@/components/customTable/Pagination'
export default {
  components: {
    Pagination,
  },
  props: {
    count: {
      type: Number,
      default: 0,
    },
    results: {
      type: Array,
      default: null,
    },
    onVisitorCenterFiltered: {
      type: Function,
      required: true,
    },
    startIndex: {
      type: Number,
      required: true,
    },
    itemsPerPage: {
      type: Number,
      required: true,
    },
    onGetData: {
      type: Function,
      required: true,
    },
  },
  data: () => ({
    loading: false,
    forms: null,
    visitorCenters: [],
    selectedVisitorCenter: null,
  }),
  computed: {
    ...mapState('app',['locale']),
  },
  mounted () {
    this.handleGetVisitorCenter()
  },
  methods: {
    handleGetVisitorCenter () {
      api.getAllWithoutLimit ('turismo', `v1/private/local/visitor-centers`)
        .then(response => {
          this.visitorCenters = response.map(item => {
            return { ID: item.ID,
                      Name: utils.getTranslation(item.Title, this.locale)
                    }
          })
          //.filter(x => (!x.MenuCheckWorkspaces || x.MenuCheckWorkspaces.indexOf(this.workspaceID) === -1) && !x.Hide && !x.HideWorkspace)
        })

    },
    getDate (v) {
      let options = { year: 'numeric', month: 'long', day: 'numeric', hour12: false };
      return new Date(v).toLocaleString('es', options) + ' → ' + new Date(v).toLocaleTimeString('es', { hour: '2-digit', minute: '2-digit' })
      //.toISOString().substring(0,16).split('T').join(' -> ')
    },
    getTranslation (v, locale) {
      return utils.getTranslation(v, locale)
    },
    getTotal(id, r) {
      const aux = this.totalResults.filter(x => x.SurveyID === id).shift()
      if (!aux || !aux[`R${r}`]) return 0
      else return aux[`R${r}`]
    },
    handleSelectVisitorCenter (v) {
      this.selectedVisitorCenter = v.Name
      this.onVisitorCenterFiltered(v.ID)
    },
  },
}
</script>
<style>
.result-head {
  font-weight: 500 !important;
  font-family: Montserrat, sans-serif !important;
  font-size: 1rem !important;
  letter-spacing: .009375em !important;
  line-height: 1.75rem;
  color: rgba(0, 0, 0, 0.6);
}
.result-row {
  color: rgba(0, 0, 0, 0.87);
  font-size: 16px;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
}
</style>

